// @ts-expect-error
import userConfig from '@airpaz/apz-js/dist/master/user-config-map';
import { useNuxtApp } from '#app';
import { Service } from '@/types/service';
import { AllFlight } from '@/types/allFlight';

import {
  Airline,
  Country,
  Holiday,
  AirlinePartner,
  Airport,
  MultiAirport,
  AirportDetail,
  RouteData,
} from '@/types/master';
import { AirlineInfo } from '@/types/airlineInfo';
import { CheapFlight } from '@/types/flightTickets';
import { Currency } from '@/types/config';

const getDefaultConfigByCountry = (country: string) => {
  return userConfig.find((el: any) => el.country === country) as
    | { country: string; lang: string; currency: string }
    | undefined;
};

export const useMasterService = () => {
  const { $apifront } = useNuxtApp();

  const getAirlineList = (params: { lang: string; all?: number; 'codes[]'?: string[] }) =>
    $apifront<Service<Airline[]>>('/ma/airline/list', { params: { all: 1, ...params } });

  const getAirlineInfo = (params: { id: string; lang: string; currency: string }) =>
    $apifront<Service<AirlineInfo>>('/ma/airline/info', { params });

  const getAirlineListByCountry = (params: { country: string }) =>
    $apifront<Service<AirlinePartner[]>>('/ma/airline/homepage-list', { params });

  const getCountryList = (params: { lang: string; all?: number }) =>
    $apifront<Service<Country[]>>('/ma/country/list', { params: { all: 1, ...params } });

  const getHolidayList = (params: { country: string; lang: string }) =>
    $apifront<Service<Holiday[]>>('/ma/holiday/list', { params });

  const getAirportList = (params: { lang: string; 'codes[]'?: string[] }) =>
    $apifront<Service<{ [code: string]: Airport }>>('/ma/airport/list', { params: { all: 1, ...params } });

  const getMultiAirport = (params: { lang: string }) =>
    $apifront<Service<{ [code: string]: MultiAirport }>>('/ma/airport/areas', { params: { all: 1, ...params } });

  const getCurrencyList = (params: { lang: string }) => $apifront<Service<Currency[]>>('/ma/currency/list', { params });

  const getAllFlight = (params: {
    destination: string;
    lang: string;
    page: number;
    country: string;
    currency?: string;
  }) => $apifront<Service<AllFlight>>('/fl/flight/to', { params });

  const getPopularListRoute = (params: { code: string; lang?: string }) =>
    $apifront<Service<RouteData[]>>('ma/airport/popular-routes', { params });

  const getPopularOriginRoutes = (params: { code: string; lang?: string }) =>
    $apifront<Service<RouteData[]>>('ma/airport/popular-origins', { params });

  const getAirlineListByRoute = (params: { depAirport: string; arrAirport: string }) =>
    $apifront<Service<any>>('/ma/airline/list-by-route', { params });

  const getCheapestFlight = (params: { depAirport: string; arrAirport: string; currency: string }) =>
    $apifront<Service<CheapFlight>>('/fl/flight/cheapest-flight', { params });

  const getAirportDetail = (params: { lang: string; airports: string[] }) =>
    $apifront<Service<{ [code: string]: AirportDetail }>>('/ma/airport/details', {
      method: 'post',
      body: params,
    });

  const getCurrencySuggestionList = (params: { language: string; country: string }) =>
    $apifront<Service<{ suggested: Currency[]; currencies: Currency[] }>>('/display-currencies', { params }).then(
      (res) => {
        const defaultCountryConfig = getDefaultConfigByCountry(params.country);

        if (defaultCountryConfig) {
          const currencies = res.result.currencies.slice();
          const suggested = res.result.suggested.slice();

          const idx = suggested.findIndex(
            (curr) =>
              curr &&
              defaultCountryConfig.currency! &&
              curr.currency.toLowerCase() === defaultCountryConfig.currency.toLowerCase(),
          );

          let defaultCurr = suggested[idx];
          if (defaultCurr) {
            suggested.splice(idx, 1);
            suggested.unshift(defaultCurr);
          } else {
            defaultCurr = currencies.find(
              (curr) => curr.currency.toLowerCase() === defaultCountryConfig.currency.toLowerCase(),
            );
            if (defaultCurr) {
              suggested.unshift(defaultCurr);
            }
          }

          res.result.suggested = suggested;
        }

        return res;
      },
    );

  const getLanguageSuggestionList = (params: { country: string }) =>
    $apifront<Service<{ suggested: { code: string; name: string }[]; languages: { code: string; name: string }[] }>>(
      '/display-languages',
      { params },
    ).then((res) => {
      const defaultCountryConfig = getDefaultConfigByCountry(params.country);

      if (defaultCountryConfig) {
        const languages = res.result.languages;
        const suggested = res.result.suggested;

        const idx = suggested.findIndex(
          (lang) => lang.code.toLowerCase() === defaultCountryConfig.lang!.toString().toLowerCase(),
        );

        let defaultLang = suggested[idx];
        if (defaultLang) {
          suggested.splice(idx, 1);
          suggested.unshift(defaultLang);
        } else {
          defaultLang = languages.find(
            (lang) => lang.code.toLowerCase() === defaultCountryConfig.lang!.toString().toLowerCase(),
          );
          if (defaultLang) {
            suggested.unshift(defaultLang);
          }
        }

        res.result.suggested = suggested;
      }

      return res;
    });

  return {
    getAirlineList,
    getAirlineInfo,
    getAirlineListByCountry,
    getCountryList,
    getHolidayList,
    getAirportList,
    getMultiAirport,
    getCurrencyList,
    getAllFlight,
    getPopularListRoute,
    getPopularOriginRoutes,
    getAirlineListByRoute,
    getCheapestFlight,
    getAirportDetail,
    getCurrencySuggestionList,
    getLanguageSuggestionList,
  };
};
